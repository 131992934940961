<template>
  <div class="report-container">
    <div class="header-bar">
      <ul class="header">
        <li :class="['header-item', index == active ? 'active': '']" 
          v-for="(item, index) in tabs" 
          :key="item.type" 
          @click="onClickTab(index)">{{ item.name }}</li>
      </ul>
      <Button type="warning" class="button" @click="addReport">添加报告</Button>
    </div>
    <Form :model="query" inline class="form">
      <FormItem label="时间：" class="inline-form-item">
        <DatePicker
          type="daterange"
          placeholder="选择时间"
          style="width: 200px"
          @on-change="handleChange"></DatePicker>
      </FormItem>
      <FormItem>
        <Select
          v-model="query.month_flag"
          style="width:200px"
          @on-change="getReports">
          <Option :value="0">默认</Option>
          <Option :value="1">最近一个月</Option>
        </Select>
      </FormItem>
      <FormItem
        label="报告名称："
        v-if="active > 0 && tabs[active].child.length > 0"
        class="inline-form-item">
        <Select
          v-model="query.pic_type"
          style="width:200px"
          @on-change="getReports">
          <Option :value="item.id" v-for="item in tabs[active].child" :key="item.id">
            {{ item.name }}
          </Option>
        </Select>
      </FormItem>
    </Form>
    <Table :columns="columns" :data="list"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate"></div>
      </Col>
      <Col span="12">
        <span class="records">共{{ totalSize }}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="totalSize" :current="query.page" :page-size="query.size" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			totalSize: 0,
			tabs: [],
			active: 0,
			query: {
				type: null,
				month_flag: 0,
				pic_type: null,
				member_id: this.$route.params.member_id,
				page: 1,
				size: 10,
			},
			list: [],
			page: 1,
			columns: [
				{
					align: 'center',
					title: '报告上传日期',
					key: 'scrq',
				},
				{
					align: 'center',
					title: '报告出具日期',
					key: 'cjrq',
				},
				{
					align: 'center',
					title: '报告类型',
					key: 'bglx',
				},
				{
					align: 'center',
					title: '检测师',
					key: 'jcs',
				},
				{
					align: 'center',
					title: '报告名称',
					key: 'bgmc',
				},
				{
					align: 'center',
					title: '报告描述',
					key: 'bgms',
				},
				{
					align: 'center',
					title: '咨询内容',
					key: 'zxnr',
				},
				{
					align: 'center',
					title: '报告预览',
					render: (h, params) => {
						const reportLink = params.row.url;
						let placeholderPath = reportLink;
						if (reportLink.match(/\.pdf$/)) {
							placeholderPath = new URL('/src/assets/img/placeholder-pdf.jpg', import.meta.url)
								.href;
						} else if (!reportLink.match(/\.(png|jpe?g|gif|svg)$/)) {
							placeholderPath = new URL('/src/assets/img/placeholder.png', import.meta.url).href;
						}
						return h(
							'a',
							{
								attrs: {
									href: reportLink,
									target: '_blank',
								},
							},
							[
								h('img', {
									attrs: {
										src: placeholderPath,
									},
									style: {
										padding: '10px 0',
										width: '100px',
										height: '100px',
										'object-fit': 'contain',
									},
								}),
							],
						);
					},
				},
				{
					align: 'center',
					title: '操作',
					width: 160,
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									style: {
										marginRight: '5px',
									},
									on: {
										click: () => {
											this.$router.push({
												name: 'memberReportAdd',
												params: { id: params.row.id },
											});
										},
									},
								}),
								h('a', {
									class: 'icon_eye',
									style: {
										marginRight: '5px',
									},
									on: {
										click: () => {
											this.$router.push({
												name: 'memberReportDetail',
												params: { id: params.row.id },
											});
										},
									},
								}),
								h('Icon', {
									attrs: {
										type: 'md-arrow-round-down',
										size: 20,
										color: '#fdad00',
									},
									style: {
										margin: '0 8px 0 6px',
										cursor: 'pointer',
									},
									on: {
										click: () => {
											const a = document.createElement('a');
											a.href = params.row.url;
											a.style.display = 'none';
											a.setAttribute('download', params.row.bgmc);
											document.body.appendChild(a);
											a.click();
											document.body.removeChild(a);
										},
									},
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.$Modal.confirm({
												title: '确认',
												content: '确定删除此报告吗？',
												onOk: () => {
													MemberService.deleteReport({ ids: params.row.id }).then(() => {
														this.$Message.success('删除成功');
														this.getReports();
													});
												},
											});
										},
									},
								}),
							]);
						} else {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									style: {
										marginRight: '5px',
									},
									on: {
										click: () => {
											this.$router.push({
												name: 'memberReportAdd',
												params: { id: params.row.id },
											});
										},
									},
								}),
								h('a', {
									class: 'icon_eye',
									style: {
										marginRight: '5px',
									},
									on: {
										click: () => {
											this.$router.push({
												name: 'memberReportDetail',
												params: { id: params.row.id },
											});
										},
									},
								}),
								h('Icon', {
									attrs: {
										type: 'md-arrow-round-down',
										size: 20,
										color: '#fdad00',
									},
									style: {
										margin: '0 8px 0 6px',
										cursor: 'pointer',
									},
									on: {
										click: () => {
											const a = document.createElement('a');
											a.href = params.row.url;
											a.style.display = 'none';
											a.setAttribute('download', params.row.bgmc);
											document.body.appendChild(a);
											a.click();
											document.body.removeChild(a);
										},
									},
								}),
							]);
						}
					},
				},
			],
			power: [],
		};
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.getReports();
		this.getReportType();
	},
	methods: {
		changePage(page) {
			this.query.page = page;
			this.getReports();
		},
		getReports() {
			this.query = { ...this.query, type: this.active || null };
			MemberService.getReportList(this.query).then((data) => {
				this.list = data.list;
				this.totalSize = data.row_size;
			});
		},
		getReportType() {
			MemberService.getReportType({ member_id: this.$route.params.member_id }).then((data) => {
				this.tabs = [{ type: null, name: '全部' }].concat(data);
			});
		},
		onClickTab(index) {
			this.active = index;
			this.query.pic_type = null;
			this.getReports();
		},
		handleChange(val) {
			if (val.every((item) => item)) {
				this.query.start_time = val[0];
				this.query.end_time = val[1];
			} else {
				this.query.start_time = null;
				this.query.end_time = null;
			}
			this.getReports();
		},
		addReport() {
			this.$router.push({ name: 'memberReportAdd' });
		},
	},
};
</script>

<style lang="less" scoped>
.report-container {
  background-color: #fff;
  padding: 15px;
  .header-bar {
    display: flex;
    background-color: #f9f9f9;
    justify-content: space-between;
    align-items: center;
  }
  .header {
    display: flex;
    &-item {
     padding: 10px 20px;
     margin: 0 5px;
     cursor: pointer;
     &.active {
       font-weight: 700;
       border-bottom: 3px #fdad00 solid;
     }
    }
  }
  .form {
    margin: 25px 0 10px;
    border-bottom: 1px solid #eee;
  }
}
</style>>
